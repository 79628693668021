import React, { Component } from "react";
import AddToCalendar from "./AddToCalendar.js";
import logo from "./logo.svg";
import Button from "./Button.js";
import SubmitURL from "./SubmitURL.js";
import "./App.css";

class App extends Component {
  getAirtable = () => {
    if (window.innerWidth <= 425) {
      return (
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/shrPIGfqdIciPHAdZ/tblRuKoSGUspiK88l?backgroundColor=blue&viewControls=on&blocks=hide&date=undefined&mode=undefined"
          frameborder="0"
          onmousewheel=""
          width={window.innerWidth < 1140 ? window.innerWidth - 30 : 1140}
          height="533"
          style={{ background: "transparent", border: "1px solid #ccc" }}
        />
      );
    } else {
      return (
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/shrPIGfqdIciPHAdZ/tblRuKoSGUspiK88l?backgroundColor=blue&viewControls=off&blocks=hide&date=undefined&mode=undefined"
          frameBorder="0"
          onmousewheel=""
          width={window.innerWidth < 1140 ? window.innerWidth - 20 : 1140}
          height="533"
          style={{ background: "transparent", border: "1px solid #ccc" }}
        />
      );
    }
  };

  render() {
    return (
      <div className="App" style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="headers">
            <img className="logo" src="logo.svg" />
            <h4 style={{ float: "left" }}>
              The list of Muslim events in SoCal
            </h4>
          </div>

          {/* Slack button */}
          {/* <div className="slack-button">
            <span id="slack_SPAN_1">
              <span id="slack_SPAN_2">
                <a
                  style={{ minWidth: 300, minHeight: 40 }}
                  href="https://onboarding.muslim.events"
                  target="_blank"
                  id="slack_A_3"
                >
                  <span style={{ fontSize: "150%", marginRight: 8 }}>👋</span>{" "}
                  Bay Area Onboarding Guide
                </a>
              </span>
            </span>
          </div> */}
        </div>

        {/* Buttons */}
        {/* <div className="container">
          <div className="subscribe-buttons">
            <Button type="messenger" title="receive updates" />
            <div className="breaking-div" style={{ height: 25 }} />
            <Button type="newsletter" title="email newsletter" />
            <div
              className="mobile-hidden"
              style={{ marginTop: 22, marginBottom: 18 }}
            >
              Subscribe to receive a weekly digest of upcoming events (<a
                href="https://muslimevents-newsletter.firebaseapp.com/"
                target="_blank"
              >
                demo
              </a>)
            </div>
            <div className="breaking-div" style={{ height: 25 }} />
          </div>
        </div> */}

        {/* Airtable */}
        {this.getAirtable()}
        <AddToCalendar />
        {/* Planning an event */}
        <div className="container" style={{ marginTop: 80, marginBottom: 90 }}>
          <h2 style={{fontSize: 27, marginBottom: 20}}>Submit an event</h2>
          <SubmitURL type="submitevent" title="Submit event" />
        </div>

        <div className="container">
          <span style={{ fontSize: 16 }}>
            Created by{" "}
            <a href="https://facebook.com/arasdean" target="_blank">
              Haroon Dean
            </a>{" "}
            &{" "}
            <a href="#" target="_blank">
              Diana Demechenkp
            </a>
          </span>
          <div style={{ marginBottom: 7, fontSize: 12 }}>
            Feel free to ping us if you have any questions!
          </div>
        </div>
      </div>
    );
  }
}

export default App;
